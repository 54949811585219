import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";

import { menuNames } from "../../Constants";

import BookAppointmentImg from "../../assets/svg/book-appointment.svg";
import ChatImg from "../../assets/svg/Communication.svg";
import CommunityMenuIcon from "../../assets/svg/CommunityMenuIcon.svg";
import EngageImg from "../../assets/svg/Engage.svg";
import ManageUsersImg from "../../assets/svg/MangeUsers.svg";
import PostImg from "../../assets/svg/Post.svg";
import ManageImg from "../../assets/svg/update.svg";

const getAllowedPaths = (menuData) => {
  let allowedPaths = [];
  // Fetching allowed portal paths
  menuData.forEach((menu) => {
    //if menu has submenu
    if (menu.data && menu.data?.length > 0) {
      const allowedSubmenus = menu.data.filter(
        (submenu) => submenu.permission === true
      );
      if (
        allowedSubmenus.length > 0 &&
        !(allowedSubmenus.length === 1 && allowedSubmenus[0].isDivider)
      ) {
        menu.data.forEach((submenu) => {
          allowedPaths = [...allowedPaths, ...(submenu.active_url ?? [])];
        });
      }
    }
    //if no submenu
    else {
      if (menu.permission === true)
        allowedPaths = [...allowedPaths, ...(menu.active_urls ?? [])];
    }
  });

  return allowedPaths;
};

const getDynamicDrawer = ({
  selectedPortal = "career",
  admin_permission = {},
  admission_admin_permission = {},
  featurePermissions = {},
  isSuperAdmin = false,
  isSchool = false,
  selectedSchoolId,
  isProduction,
  isSchoolAdmin = false,
  isGeneralAdvisor = false,
  returnPaths = false,
  user = {},
}) => {
  const careerMenus = [
    {
      name: menuNames.USERS,
      icon: <img src={ManageUsersImg} alt="Manager users" />,
      title: "Manage users",
      active_urls: [
        "/manage-user/team",
        "/manage-user/add-team",
        "/manage-user/schools",
        "/manage-user/add-schools",
        "/manage-user/admin",
        "/manage-user/add-admin",
        "/manage-user/user",
        "/manage-user/add-user",
        "/manage-user/alumni",
        "/manage-user/add-alumni",
        "/manage-user/staff-and-faculty",
        "/manage-user/add-staff",
        "/manage-user/pending-requests",
        "/manage-user/leads",
        "/manage-user/keywords",
      ],
      data: [
        {
          name: "Interstride Team",
          title: "Manage Interstride accounts",
          url: "/manage-user/team",
          active_url: ["/manage-user/team", "/manage-user/add-team"],
          permission: isSuperAdmin,
        },
        {
          name: "Schools",
          title: "Manage Schools",
          url: "/manage-user/schools",
          active_url: ["/manage-user/schools", "/manage-user/add-schools"],
          permission: isSuperAdmin,
        },
        {
          name: "Admins",
          title: "Manage Admin accounts",
          url: "/manage-user/admin",
          active_url: ["/manage-user/admin", "/manage-user/add-admin"],
          permission:
            isSuperAdmin || isSchool || admin_permission["admin_users"],
        },
        {
          name: "Students",
          title: "Manage student accounts",
          url: "/manage-user/user",
          active_url: [
            "/manage-user/user",
            "/manage-user/add-user",
            "/manage-user/add-user?tab=Registered",
          ],
          permission:
            isSuperAdmin || isSchool || admin_permission["registered_users"],
        },
        {
          name: "Alumni",
          title: "Manage alumni accounts",
          url: "/manage-user/alumni",
          active_url: ["/manage-user/alumni", "/manage-user/add-alumni"],
          permission:
            isSuperAdmin || isSchool || admin_permission["alumni_requests"],
        },
        {
          name: "Staff & Faculty",
          title: "Manage staff and faculty accounts",
          url: "/manage-user/staff-and-faculty",
          active_url: [
            "/manage-user/staff-and-faculty",
            "/manage-user/add-staff",
          ],
          permission:
            isSuperAdmin || isSchool || admin_permission["alumni_requests"],
        },
        {
          name: "Pending Requests",
          title: "Pending Requests",
          url: "/manage-user/pending-requests",
          active_url: ["/manage-user/pending-requests"],
          permission: isSuperAdmin || admin_permission["pending_requests"],
        },
        {
          name: "Leads",
          title: "Manage student leads",
          url: "/manage-user/leads",
          active_url: ["/manage-user/leads"],
          permission: isSuperAdmin,
        },
      ],
    },
    {
      name: menuNames.POST,
      icon: <img src={PostImg} alt="Post" />,
      title: "Edit and add new content",
      active_urls: [
        "/post/home-feed",
        "/post/add-home-feed",
        "/post/edit-home-feed",
        "/post/events",
        "/post/add-events",
        "/post/add-event-category",
        "/post/resources",
        "/post/add-resources",
        "/post/student-services",
        "/post/add-student-services",
        "/post/add-category",
        "/post/promotions",
        "/post/add-promotion",
        "/post/add-student-service-category",
        "/post/checklists",
        "/post/add-checklists-category",
        "/post/add-event-rss",
        "/manage-rss/event",
        "/manage-rss/post",
        "/post/add-post-rss",
        "/post/jobsandinternship",
        "/post/add-jobsandinternship",
        "/post/country",
        "/post/add-country-city",
        "/post/events",
        "/post/add-job-rss",
        "/manage-rss/job",
        // "/post/career-gameplan/qanda",
        // "/post/career-gameplan/add-qanda",
        "/post/career-gameplan/guidance",
        "/post/career-gameplan/add-guidance",
        "/post/employment-data",
        "/post/employment-data-import",
        "/post/employment-data-edit",
      ],
      data: [
        {
          isDivider: true,
          title: "POST ON HOME",
          permission:
            isSuperAdmin ||
            isSchool ||
            admin_permission["user_post"] ||
            admin_permission["promote"],
        },
        {
          name: "Home Feed",
          title: "Front page articles, content, and announcements",
          url: "/post/home-feed",
          id: 0,
          active_url: [
            "/post/home-feed",
            "/post/add-home-feed",
            "/post/edit-home-feed",
            "/post/add-post-rss",
            "/manage-rss/post",
          ],
          permission: isSuperAdmin || isSchool || admin_permission["user_post"],
        },
        {
          name: "Promote",
          title: "Manage promotes on the portal",
          url: "/post/promotions",
          active_url: ["/post/add-promotion", "/post/promotions"],
          permission: isSuperAdmin || isSchool || admin_permission["promote"],
        },
        {
          isDivider: true,
          title: "POST ON JOBS",
          permission: isSuperAdmin || isSchool || admin_permission["jobs"],
        },

        {
          name: "Jobs & Internships",
          title: "Add international friendly opportunities",
          url: "/post/jobsandinternship",
          active_url: [
            "/post/jobsandinternship",
            "/post/add-jobsandinternship",
            "/post/add-job-rss",
            "/manage-rss/job",
          ],
          permission: isSuperAdmin || isSchool || admin_permission["jobs"],
        },
        {
          name: "Country Insights",
          title: "Edit Country Insights",
          url: "/post/country",
          active_url: ["/post/country", "/post/add-country-city"],
          permission: isSuperAdmin,
        },
        {
          name: "Employment Data",
          title: "Employment Data",
          url: "/post/employment-data",
          active_url: [
            "/post/employment-data",
            "/post/employment-data-import",
            "/post/employment-data-edit",
          ],
          permission:
            isSuperAdmin ||
            ((isSchool || isSchoolAdmin) &&
              featurePermissions["employment_data"]),
        },
        {
          isDivider: true,
          title: "POST ON LEARN",
          permission:
            isSuperAdmin ||
            ((featurePermissions["webinar"] ||
              featurePermissions["resources"] ||
              featurePermissions["student_services"]) &&
              (isSchool ||
                admin_permission["webinar"] ||
                admin_permission["videos"] ||
                admin_permission["deal"])),
        },
        {
          name: "Webinars & Events",
          title: "Edit and add new events",
          url: "/post/events",
          id: 1,
          active_url: [
            "/post/events",
            "/post/add-events",
            "/post/add-event-category",
            "/post/add-event-rss",
            "/manage-rss/event",
          ],
          permission:
            isSuperAdmin ||
            (featurePermissions["webinar"] &&
              (isSchool || admin_permission["webinar"])),
        },
        {
          name: "Resources",
          title: "Customize your resource library",
          url: "/post/resources",
          id: 2,
          active_url: [
            "/post/resources",
            "/post/add-resources",
            "/post/add-category",
          ],
          permission:
            isSuperAdmin ||
            (featurePermissions["resources"] &&
              (isSchool || admin_permission["videos"])),
        },
        {
          name: "Student Services",
          title: "Edit and add recommended student services providers",
          url: "/post/student-services",
          id: 3,
          active_url: [
            "/post/student-services",
            "/post/add-student-services",
            "/post/add-student-service-category",
          ],
          permission:
            isSuperAdmin ||
            (featurePermissions["student_services"] &&
              (isSchool || admin_permission["deal"])),
        },
        {
          isDivider: true,
          title: "POST ON TOOLS",
          permission:
            isSuperAdmin ||
            ((featurePermissions["gameplan"] ||
              featurePermissions["checklist"]) &&
              (isSchool ||
                admin_permission["gameplan"] ||
                admin_permission["checklist"])),
        },
        {
          name: "Career Gameplan",
          url: "/post/career-gameplan/guidance",
          icon: <InsertEmoticonIcon />,
          active_url: [
            "/post/career-gameplan/guidance",
            "/post/career-gameplan/add-guidance",
          ],
          data: [
            {
              name: "Guidance",
              url: "/post/career-gameplan/guidance",
              active_url: [
                "/post/career-gameplan/guidance",
                "/post/career-gameplan/add-guidance",
              ],
            },
          ],
          permission:
            isSuperAdmin ||
            (featurePermissions["gameplan"] &&
              (isSchool || admin_permission["gameplan"])),
        },
        {
          name: "Checklists",
          title: "Manage checklists on the portal",
          url: "/post/checklists",
          active_url: ["/post/checklists", "/post/add-checklists-category"],
          permission:
            isSuperAdmin ||
            (featurePermissions["checklist"] &&
              (isSchool || admin_permission["checklist"])),
        },
      ],
    },
    {
      name: menuNames.NETWORK,
      title: "Network with international peers and alumni at your institution",
      icon: <img src={ChatImg} alt="Chat" />,
      active_urls: [
        "/communication/topics",
        "/communication/discover-topic",
        "/communication/add-topic",
        "/communication/manage-topic",
        "/chat/messages",
        "/chat/add-message",
        "/chat/add-group",
      ],

      hideOnSidebar: true,
      data: [
        {
          name: "Messages",
          title:
            "Send individual or group messages to your international student community",
          url: "/chat/messages",
          active_url: [
            "/chat/messages",
            "/chat/add-message",
            "/chat/add-group",
          ],
          permission:
            !isSuperAdmin ||
            (featurePermissions["chat"] &&
              (isSchool || admin_permission["chat"])),
        },
        {
          name: "Communities",
          title: "Create cohorts within your international student Community",
          url: "/communication/topics",
          active_url: [
            "/communication/topics",
            "/communication/add-topic",
            "/communication/manage-topic",
            "/communication/discover-topic",
          ],
          permission:
            isSuperAdmin ||
            (featurePermissions["topics"] &&
              (isSchool || admin_permission["topics"])),
        },
      ],
    },
    {
      name: menuNames.ENGAGE,
      icon: <img src={EngageImg} alt="engage" />,
      title: "Download marketing materials",
      active_urls: [
        "/engage",
        "/engage/analytics",
        "/engage/marketing-kit/add-email-templates",
        "/engage/marketing-kit/email-templates",
        "/engage/marketing-kit/add-brochures",
        "/engage/marketing-kit/brochures",
        "/engage/marketing-kit/add-videos",
        "/engage/marketing-kit/videos",
        "/engage/marketing-kit/brand-assets",
        "/engage/marketing-kit/add-brand-assets",
        "/engage/marketing-kit/add-onboarding-resources",
        "/engage/marketing-kit/onboarding-resources",
        "/engage/studentfeedback",
        "/engage/adminfeedback",
        "/engage/community-suggestions",
        "/engage/marketing-kit",
      ],
      data: [
        {
          name: "Analytics",
          title: "Analytics",
          url: "/engage/analytics",
          active_url: ["/engage/analytics"],
          permission:
            isSuperAdmin ||
            ((isSchool || isSchoolAdmin) && featurePermissions["analytics"]),
        },
        {
          name: "Marketing Hub",
          url: "/engage/marketing-kit",
          icon: <InsertEmoticonIcon />,
          permission:
            !isGeneralAdvisor &&
            (isSuperAdmin || isSchool || admin_permission["kits"]),
          title: "Manage marketing-kit on portal",
          active_url: [
            "/engage/marketing-kit/add-email-templates",
            "/engage/marketing-kit/email-templates",
            "/engage/marketing-kit/add-videos",
            "/engage/marketing-kit/videos",
            "/engage/marketing-kit/add-brochures",
            "/engage/marketing-kit/brochures",
            "/engage/marketing-kit/add-brand-assets",
            "/engage/marketing-kit/brand-assets",
            "/engage/marketing-kit/add-onboarding-resources",
            "/engage/marketing-kit/onboarding-resources",
            "/engage/marketing-kit",
          ],
          data: [
            {
              name: "Promotional Videos",
              title: "Add or edit videos marketing kit",
              url: "/engage/marketing-kit/videos",
              active_url: [
                "/engage/marketing-kit/add-videos",
                "/engage/marketing-kit/videos",
              ],
            },
            {
              name: "Email Templates",
              title: "Add or edit email templates marketing kit",
              url: "/engage/marketing-kit/email-templates",
              active_url: [
                "/engage/marketing-kit/add-email-templates",
                "/engage/marketing-kit/email-templates",
              ],
            },
            {
              name: "Brochures",
              title: "Add or edit Brochures marketing kit",
              url: "/engage/marketing-kit/brochures",
              active_url: [
                "/engage/marketing-kit/add-brochures",
                "/engage/marketing-kit/brochures",
              ],
            },
            {
              name: "Brand Assets",
              title: "Add or edit Brand assets marketing kit",
              url: "/engage/marketing-kit/brand-assets",
              active_url: [
                "/engage/marketing-kit/add-brand-assets",
                "/engage/marketing-kit/brand-assets",
              ],
            },
            {
              name: "Onboarding Resources",
              title: "Add or edit Onboarding Resources",
              url: "/engage/marketing-kit/onboarding-resources",
              active_url: [
                "/engage/marketing-kit/add-onboarding-resources",
                "/engage/marketing-kit/onboarding-resources",
              ],
            },
          ],
        },
        {
          name: "Student Feedback",
          title: "Read student feedback on Interstride",
          url: "/engage/studentfeedback",
          active_url: ["/engage/studentfeedback"],
          permission: isSuperAdmin,
        },
        {
          name: "Admin Feedback",
          title: "Read admin feedback on Interstride",
          url: "/engage/adminfeedback",
          active_url: ["/engage/adminfeedback"],
          permission: isSuperAdmin,
        },
        {
          name: "Community Suggestions",
          title: "Community Suggestions",
          url: "/engage/community-suggestions",
          active_url: ["/engage/community-suggestions"],
          permission: isSuperAdmin,
        },
      ],
    },
    {
      name: menuNames.MANAGE,
      title: "Manage",
      icon: <img src={ManageImg} alt="Manage" />,
      active_urls: ["/manage/features", "/manage/versions"],
      permission:
        !isGeneralAdvisor &&
        (isSuperAdmin || isSchool || admin_permission["administrator_role"]),
      data: [
        {
          name: "Features",
          title: "Manage features",
          url: "/manage/features",
          active_url: ["/manage/features"],
          permission:
            isSuperAdmin || isSchool || admin_permission["administrator_role"],
        },
        {
          name: "Versions",
          title: "Manage Versions",
          url: "/manage/versions",
          active_url: ["/manage/versions"],
          permission: isSuperAdmin,
        },
      ],
    },
    {
      name: menuNames.MEET,
      icon: <img src={BookAppointmentImg} alt="Meet" />,
      title: "Meet",
      active_urls: [
        "/appointment",
        "/appointment/add-appointment",
        "/appointment/edit-appointment",
        "/appointment/set-hours",
        "/appointment/advisors",
        "/appointment/add-advisors",
        "/appointment/settings",
        "/appointment/set-calendar",
      ],
      data: [
        {
          name: "Calendar",
          title: "Schedule a meeting",
          url: "/appointment",
          active_url: [
            "/appointment",
            "/appointment/add-appointment",
            "/appointment/edit-appointment",
            "/appointment/set-hours",
            "/appointment/settings",
            "/appointment/set-calendar",
          ],
          permission:
            isGeneralAdvisor ||
            isSuperAdmin ||
            (featurePermissions["appointment"] &&
              (isSchool || admin_permission["appointment"])),
        },
        {
          name: "Invitation",
          title:
            "Invite other advisors who support your international students.",
          url: "/appointment/advisors",
          active_url: ["/appointment/advisors", "/appointment/add-advisors"],
          permission:
            isSuperAdmin ||
            (featurePermissions["appointment"] &&
              (isSchool ||
                (admin_permission["advisors_users"] && !isGeneralAdvisor))),
        },
      ],
    },
  ];

  const admissionsMenus = [
    {
      name: menuNames.STATS,
      icon: <img src={EngageImg} alt="Statistics" />,
      title: "User Statistics",
      active_urls: [
        "/admissions/stats/statistics",
        "/admissions/stats/statistics/engagement-details",
        "/admissions/stats/track-sign-ups",
        "/admissions/stats/add-utm",
      ],
      data: [
        {
          name: "Statistics",
          title: "Statistics",
          url: "/admissions/stats/statistics",
          active_url: [
            "/admissions/stats/statistics",
            "/admissions/stats/statistics/engagement-details",
          ],
          permission: true, // Super Admin + School + School Admins (As we don't have any roles for school admins on AA everyone is an administrator)
        },
        {
          name: "Track Sign Ups",
          title: "Track Sign Ups",
          url: "/admissions/stats/track-sign-ups",
          active_url: [
            "/admissions/stats/track-sign-ups",
            "/admissions/stats/add-utm",
          ],
          permission: true, // Super Admin + School + School Admins (As we don't have any roles for school admins on AA everyone is an administrator)
        },
      ],
    },

    {
      name: "USERS",
      icon: <img src={ManageUsersImg} alt="Manager users" />,
      title: "Manage users",
      active_urls: [
        "/admissions/manage-user/admin",
        "/admissions/manage-user/add-admin",
        // "/admissions/manage-user/schools", // disabling due to IADMIN-1738
        "/admissions/manage-user/student-ambassadors",
        "/admissions/manage-user/add-student-ambassador",
        "/admissions/manage-user/alumni-ambassadors",
        "/admissions/manage-user/add-alumni-ambassador",
        "/admissions/manage-user/staff-ambassadors",
        "/admissions/manage-user/add-staff-ambassador",
        "/admissions/manage-user/prospects",
        "/admissions/manage-user/add-prospect",
      ],
      data: [
        {
          name: "Prospects",
          title: "Manage Prospects accounts",
          url: "/admissions/manage-user/prospects",
          active_url: [
            "/admissions/manage-user/prospects",
            "/admissions/manage-user/add-prospect",
          ],
          permission:
            isSuperAdmin || isSchool || admission_admin_permission["prospects"],
        },
        {
          name: "Student Ambassadors",
          title: "Manage Student Ambassador accounts",
          url: "/admissions/manage-user/student-ambassadors",
          active_url: [
            "/admissions/manage-user/student-ambassadors",
            "/admissions/manage-user/add-student-ambassador",
          ],
          permission:
            isSuperAdmin ||
            isSchool ||
            admission_admin_permission["student_ambassadors"],
        },
        {
          name: "Alumni Ambassadors",
          title: "Manage Alumni Ambassador accounts",
          url: "/admissions/manage-user/alumni-ambassadors",
          active_url: [
            "/admissions/manage-user/alumni-ambassadors",
            "/admissions/manage-user/add-alumni-ambassador",
          ],
          permission:
            isSuperAdmin ||
            isSchool ||
            admission_admin_permission["alumni_ambassadors"],
        },
        {
          name: "Staff Ambassadors",
          title: "Manage Staff Ambassador accounts",
          url: "/admissions/manage-user/staff-ambassadors",
          active_url: [
            "/admissions/manage-user/staff-ambassadors",
            "/admissions/manage-user/add-staff-ambassador",
          ],
          permission: true,
          // isSuperAdmin ||
          // isSchool ||
          // admission_admin_permission["staff_ambassadors"],
        },
        {
          name: "Admin",
          title: "Manage Admin accounts",
          url: "/admissions/manage-user/admin",
          active_url: [
            "/admissions/manage-user/admin",
            "/admissions/manage-user/add-admin",
          ],
          permission:
            isSuperAdmin ||
            isSchool ||
            admission_admin_permission["admin_users"],
        },
        // {
        //   name: "Schools",
        //   title: "Manage Schools",
        //   url: "/admissions/manage-user/schools",
        //   active_url: ["/admissions/manage-user/schools"],
        //   permission: isSuperAdmin,
        // },
      ],
    },
    {
      name: "POST",
      icon: <img src={PostImg} alt="Post" />,
      title: "Edit and add new content",
      active_urls: [
        "/admissions/post/home-feed",
        "/post/add-home-feed",
        "/post/edit-home-feed",
        "/admissions/post/webinars",
        "/post/add-events",
        "/post/add-event-category",
        "/admissions/post/add-event-rss",
        "/manage-rss/event",
        "/admissions/post/resources",
        "/post/add-resources",
        "/post/add-category",
        "/admissions/post/student-services",
        "/post/add-student-services",
        "/post/add-student-service-category",
        "/admissions/post/campus-map",
        "/admissions/post/campus-map-add-category",
        "/admissions/post/campus-map-add-place",
        "/admissions/post/checklists",
        "/admissions/post/add-checklists-category",
        "/admissions/post/employment-data",
        "/admissions/post/employment-data-import",
        "/admissions/post/employment-data-edit",
        "/admissions/post/calculator",
        "/admissions/post/add-calculator-field",
        "/admissions/post/scholarships",
        "/admissions/post/add-scholarship",
      ],
      data: [
        {
          isDivider: true,
          title: "POST ON HOME",
          permission:
            isSuperAdmin || isSchool || admission_admin_permission["homefeed"],
        },
        {
          name: "Home Feed",
          title: "Front page articles, content, and announcements",
          url: "/admissions/post/home-feed",
          id: 0,
          active_url: [
            "/admissions/post/home-feed",
            "/post/add-home-feed",
            "/post/edit-home-feed",
          ],
          permission:
            isSuperAdmin || isSchool || admission_admin_permission["homefeed"],
        },
        {
          isDivider: true,
          title: "POST ON LEARN",
          permission:
            isSuperAdmin ||
            ((featurePermissions["webinar"] ||
              featurePermissions["resources"] ||
              featurePermissions["student_services"] ||
              featurePermissions["checklist"]) &&
              (isSchool ||
                admission_admin_permission["webinar"] ||
                admission_admin_permission["resources"] ||
                admission_admin_permission["student_services"] ||
                featurePermissions["checklist"])),
        },
        {
          name: "Webinars",
          title: "Edit and add new events",
          url: "/admissions/post/webinars",
          id: 1,
          active_url: [
            "/admissions/post/webinars",
            "/post/add-events",
            "/post/add-event-category",
            "/admissions/post/add-event-rss",
            "/manage-rss/event",
          ],
          permission:
            isSuperAdmin ||
            (featurePermissions["webinar"] &&
              (isSchool || admission_admin_permission["webinar"])),
        },
        {
          name: "Resources",
          title: "Customize your resource library",
          url: "/admissions/post/resources",
          id: 2,
          active_url: [
            "/admissions/post/resources",
            "/post/add-resources",
            "/post/add-category",
          ],
          permission:
            isSuperAdmin ||
            (featurePermissions["resources"] &&
              (isSchool || admission_admin_permission["resources"])),
        },
        {
          name: "Student Services",
          title: "Edit and add recommended student services providers",
          url: "/admissions/post/student-services",
          id: 3,
          active_url: [
            "/admissions/post/student-services",
            "/post/add-student-services",
            "/post/add-student-service-category",
          ],
          permission:
            isSuperAdmin ||
            (featurePermissions["student_services"] &&
              (isSchool || admission_admin_permission["student_services"])),
        },
        {
          name: "Checklists",
          title: "Manage checklists on the portal",
          url: "/admissions/post/checklists",
          active_url: [
            "/admissions/post/checklists",
            "/admissions/post/add-checklists-category",
          ],
          permission:
            isSuperAdmin ||
            (featurePermissions["checklist"] &&
              (isSchool || admission_admin_permission["checklist"])),
        },
        {
          isDivider: true,
          title: "POST ON TOOLS",
          permission:
            isSuperAdmin ||
            ((isSchool || isSchoolAdmin) &&
              (featurePermissions["calculator"] ||
                featurePermissions["campus_map"] ||
                featurePermissions["employment_data"])),
        },
        {
          name: "Calculator",
          // TODO : tooltip
          title: "Calculator",
          url: "/admissions/post/calculator",
          id: 4,
          active_url: [
            "/admissions/post/calculator",
            "/admissions/post/add-calculator-field",
          ],
          permission:
            isSuperAdmin ||
            ((isSchool || isSchoolAdmin) &&
              featurePermissions["cost_calculator"]),
        },
        {
          name: "Scholarships",
          // TODO : tooltip
          title: "Scholarships",
          url: "/admissions/post/scholarships",
          id: 5,
          active_url: [
            "/admissions/post/scholarships",
            "/admissions/post/add-scholarship",
          ],
          permission:
            isSuperAdmin ||
            ((isSchool || isSchoolAdmin) &&
              featurePermissions["cost_calculator"]),
        },
        {
          name: "Campus Map",
          title: "Edit and add hot spots for international students on campus",
          url: "/admissions/post/campus-map",
          id: 6,
          active_url: [
            "/admissions/post/campus-map",
            "/admissions/post/campus-map-add-category",
            "/admissions/post/campus-map-add-place",
          ],
          permission:
            isSuperAdmin ||
            ((isSchool || isSchoolAdmin) && featurePermissions["campus_map"]),
        },
        {
          name: "Career",
          title: "Career",
          url: "/admissions/post/employment-data",
          active_url: [
            "/admissions/post/employment-data",
            "/admissions/post/employment-data-import",
            "/admissions/post/employment-data-edit",
          ],
          permission:
            isSuperAdmin ||
            ((isSchool || isSchoolAdmin) &&
              featurePermissions["employment_data"]),
        },
      ],
    },
    {
      name: menuNames.CHAT,
      title: "Network with international peers and alumni at your institution",
      icon: <img src={ChatImg} alt="Chat" />,
      url: "/admissions/chat/messages",
      hideOnSidebar: true,
      active_urls: [
        "/admissions/chat/messages",
        "/admissions/chat/add-message",
        "/admissions/chat/add-group",
      ],
      permission:
        !isSuperAdmin ||
        (featurePermissions["chat"] &&
          (isSchool || admission_admin_permission["chat"])),
    },
    {
      name: menuNames.GROUPS,
      title: "Create cohorts within your international student Community",
      icon: <img src={CommunityMenuIcon} alt="Community" />,
      url: "/admissions/chat/topics",
      active_urls: [
        "/admissions/chat/topics",
        "/admissions/chat/add-topic",
        "/admissions/chat/manage-topic",
        "/communication/discover-topic",
      ],
      hideOnSidebar: true,
      permission:
        isSuperAdmin ||
        (featurePermissions["topics"] &&
          (isSchool || admission_admin_permission["topics"])),
    },
    {
      name: menuNames.MANAGE,
      title: "Manage",
      icon: <img src={ManageImg} alt="Manage" />,
      active_urls: ["/admissions/manage/features", "/manage/sign-up"],
      data: [
        {
          name: "Features",
          title: "Manage features",
          url: "/admissions/manage/features",
          active_url: ["/admissions/manage/features"],
          permission: isSuperAdmin || isSchool || isSchoolAdmin,
        },
        {
          name: "Sign Up Fields",
          title: "Determine what information you want to collect on onboarding",
          url: "/manage/sign-up",
          active_url: ["/manage/sign-up"],
          permission: isSuperAdmin || isSchool || isSchoolAdmin,
        },
      ],
    },
    {
      name: menuNames.ENGAGE,
      icon: <img src={EngageImg} alt="engage" />,
      title: "Download marketing materials",
      active_urls: [
        "/admissions/engage/marketing-kit",
        "/admissions/engage/add-marketing-kit",
        "/admissions/engage/prospectfeedback",
        "/admissions/engage/adminfeedback",
      ],
      data: [
        {
          name: "Marketing Hub",
          url: "/admissions/engage/marketing-kit",
          icon: <InsertEmoticonIcon />,
          permission:
            isSuperAdmin ||
            isSchool ||
            // As we have BE issue with permission we have to consider this as true if not present
            (admission_admin_permission["kits"] ?? true),
          title: "Manage marketing-kit on portal",
          active_url: [
            "/admissions/engage/marketing-kit",
            "/admissions/engage/add-marketing-kit",
          ],
        },
        {
          name: "Prospect Feedback",
          title: "Read prospect feedback on Interstride",
          url: "/admissions/engage/prospectfeedback",
          active_url: ["/admissions/engage/prospectfeedback"],
          permission: isSuperAdmin,
        },
        {
          name: "Admin Feedback",
          title: "Read admin feedback on Interstride",
          url: "/admissions/engage/adminfeedback",
          active_url: ["/admissions/engage/adminfeedback"],
          permission: isSuperAdmin,
        },
      ],
    },
  ];

  if (returnPaths) {
    const allowedCareerPaths = getAllowedPaths(careerMenus);
    const allowedAdmissionsPaths = getAllowedPaths(admissionsMenus);
    if (isGeneralAdvisor) return { allowedCareerPaths };
    else return { allowedCareerPaths, allowedAdmissionsPaths };
  } else {
    const sidebarData =
      isGeneralAdvisor || selectedPortal !== "admission"
        ? careerMenus
        : admissionsMenus;

    const allowedMenus = [];

    sidebarData.forEach((menu) => {
      //if menu has submenu
      if (menu.data && menu.data?.length > 0) {
        const data = menu.data.filter((submenu) => submenu.permission === true);
        if (data.length > 0 && !(data.length === 1 && data[0].isDivider)) {
          // Skip if only the divider is there
          allowedMenus.push({ ...menu, data });
        }
      }
      //if no submenu
      else {
        if (menu.permission === true) allowedMenus.push({ ...menu });
      }
    });

    return allowedMenus;
  }
};

export default getDynamicDrawer;
